import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import HeroChevron from "../../components/hero/hero-chevron";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import VimeoVideoFrame from "../../components/custom-widgets/vimeo-video-frame";

const ReviewCard = ({ title, review, reviewer, reviewerInfo }) => (
  <div className="col-12 col-md-6 mb-3">
    <div className="bg-white border-radius-12 shadow px-4 pt-2 pb-4" style={{ height: "100%" }}>
      <h3 className="text-green-60" style={{ textIndent: "-0.6ch" }} dangerouslySetInnerHTML={{ __html: title }} />
      {review
        ? review?.map((paragraph, index) => (
            <p
              key={index}
              style={{ textIndent: index === 0 ? "-0.6ch" : null }}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          ))
        : null}
      <strong className="mb-0">{reviewer}</strong>
      {reviewerInfo?.map((info, index) => (
        <p key={index} className="mb-0" dangerouslySetInnerHTML={{ __html: info }} />
      ))}
    </div>
  </div>
);

const VideoReviewSection = ({ videoData, review, reviewer, reviewerTitle, reviewerLocation }) => (
  <section className="bg-dark-green-01 text-white">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 mb-0 mb-2 mb-lg-0">{videoData && <VimeoVideoFrame {...videoData} />}</div>
        <div className="col-12 col-md-6">
          <h3 style={{ textIndent: "-0.6ch" }}>{review}</h3>
          <p className="mb-0">
            <strong>{reviewer}</strong>
          </p>
          <p className="mb-0">{reviewerTitle}</p>
          <p className="mb-0">{reviewerLocation}</p>
        </div>
      </div>
    </div>
  </section>
);

const Reviews = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/about-us/reviews/hero-reviews-04032023-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/about-us/reviews/hero-reviews-04032023-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/about-us/reviews/hero-reviews-04032023-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/about-us/reviews/hero-reviews-04032023-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/about-us/reviews/hero-reviews-04032023-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/about-us/reviews/hero-reviews-04032023-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/about-us/reviews/hero-reviews-04032023-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
      pelicanBrewingCompany: file(relativePath: { eq: "third-party/thumbnail-pelican-brewing-company.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 200
            transformOptions: { cropFocus: CENTER }
            layout: FIXED
          )
        }
      }
    }
  `);

  const title = "WaFd Bank Reviews - Read Customer Reviews of WaFd Bank",
    description =
      "Curious about banking with WaFd Bank? Read real reviews of WaFd Bank & learn what it's like to be a customer of WaFd Bank whether it's for business or personal.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        property: "og:title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/reviews"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-reviews-04032023.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      active: "active",
      title: "Reviews"
    }
  ];

  const heroChevronData = {
    id: "reviews-hero",
    ...getHeroImgVariables(imgData),
    altText: "Businesswoman using her laptop in the office.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "See Why WaFd Bank Has Been Awarded <a href='/about-us/best-bank' class='text-white' id='hero-title-link'>Best Bank!</a>",
            class: "text-white"
          }
        },
        {
          id: 2,
          button: {
            text: "Leave a Review",
            url: "/locations",
            class: "btn btn-white"
          }
        }
      ]
    }
  };

  const videoReviewFirstSection = {
    review: '"From the deepest part of my heart, our company wouldn\'t have made it through without WaFd"',
    reviewer: "Adrian Perez",
    reviewerLocation: "Albuquerque, New Mexico",
    reviewerTitle: "President of Heritage Hotels & Resorts Explains WaFd Bank Partnership",
    videoData: {
      title: "Adrian Perez, President of Heritage Hotels & Resorts Explains WaFd Bank Partnership",
      vimeoId: "577337591",
      minHeight: "360px",
      class: "m-auto iframe w-100 border-radius-12"
    }
  };

  const reviewsFirstSection = [
    {
      title: '"They have a deep knowledge of all aspects of housing..."',
      review: [
        '"WaFd Bank significantly lowered our banking costs and continues to provide us with quick decisions based on their presence and knowledge of the local market. They have a deep knowledge of all aspects of housing, from forestry to lending, and that fits Dunn."'
      ],
      reviewer: "Mike Dunn",
      reviewerInfo: ["President, Dunn Lumber", "Seattle, WA"]
    },
    {
      title: '"We love the daily, extra-mile service..."',
      review: [
        '"We love the daily, extra-mile service, such as checking in to re-evaluate our credit line mid-term. With our previous bank, it was like pulling teeth to convince them we were worthy of a line increase. WaFd Bank has always been consistent and solid."'
      ],
      reviewer: "Tiffany Stewart",
      reviewerInfo: ["President, Advanced Medical", "Issaquah, WA"]
    },
    {
      title: '"I like the fact that WaFd does not sell their loans and has more flexibility in the process."',
      review: [
        '"I began trying to refinance my house after rates fell this summer. I spoke with three other banks who said they could not help or were not interested because of the uniqueness of my property (on 5 acres, 1st and 2nd loans on property) I found WaFd Bank. They were more than willing to work with me. I like the fact that WaFd does not sell their loans and has more flexibility in the process. It was not a “one size fits all” approach. I highly recommend anyone looking to refinance or switch banks to contact WaFd Bank!"'
      ],
      reviewer: "Joe W.",
      reviewerInfo: ["Denton, Texas"]
    },
    {
      title: '"That was simply amazing. That would not happen at any bank except WaFd Bank."',
      review: [
        "\"We are stoked that you were involved helping us. Your teams have been working like crazy and we have had numerous times in the last year and a half when one WaFd Bank employee was overlapping another WaFd employee unknowingly. That probably wouldn't happen at another bank because they don't work as hard as WaFd. Heck, the CEO called me after midnight in April 2020 to get our Ops loan approved initially. That was simply amazing. That would not happen at any bank except WaFd Bank. You and your teammates absolutely rock. THANK YOU.\""
      ],
      reviewer: "T.F.",
      reviewerInfo: ["Business Owner/Developer"]
    }
  ];

  const videoReviewSecondSection = {
    review:
      '"WaFd Bank is a partner that enables us to grow and understands our business and really helps us make the decisions that we need to continue to grow and be relevant in the marketplace."',
    reviewer: "Jim Prinzing",
    reviewerLocation: "Pacific City, OR",
    reviewerTitle: "CEO, Pelican Brewing Company",
    videoData: {
      title: "WaFd Bank Commercial Partner - Pelican Brewing Company",
      vimeoId: "409857930",
      minHeight: "360px",
      class: "m-auto iframe w-100 border-radius-12"
    }
  };

  const reviewsSecondSection = [
    {
      title: '"Very professional and helpful."',
      review: [
        '"Jessica Juarez of the Ketchum branch did a wonderful job with my PPP loan. Very professional and helpful. Thank you WaFd Bank!"'
      ],
      reviewer: "John Foley",
      reviewerInfo: ["CEO, John Foley Inc.", "Former Lead Solo Pilot &mdash; Blue Angels", "Sun Valley, ID"]
    },
    {
      title: '"Amazing team! WaFd really stepped up during this difficult time..."',
      review: [
        "\"Amazing team! WaFd really stepped up during this difficult time helping people with the PPP Loan. I've been a client for a few years and I've always been satisfied with their customer service!\""
      ],
      reviewer: "Mathieu C.",
      reviewerInfo: ["Las Vegas, NV"]
    },
    {
      title:
        '"It is not often that a business truly qualifies as a disruptor &mdash; someone doing it better, differently, in a way that shows truly how far behind the competition is."',
      review: [
        "\"WaFd's team and operation are second to none &mdash; we recently moved our banking away from a big bank, to WaFd, and our founders group and finance teams are impressed at WaFd's professionalism, 1:1 care, responsiveness, communication, and ability to get results. WaFd gets it done, as I drive through Washington, every time I see a WaFd branch, I smile as I know my company is with the best bank, the disruptive bank that gets it done and done well!",
        'WaFd is a fantastic solution for any new tech company &mdash; and is a great partner for our Video Game Company based here in Washington. We appreciate their care, diligence, and professionalism, and we look forward to a long relationship with Brent and the amazing WaFd team."'
      ],
      reviewer: "Kevin Franklin",
      reviewerInfo: ["CEO, Final Strike Games", "Bellevue, WA"]
    },
    {
      title: '"WaFd got it done. I am incredibly grateful for their hard work."',
      review: [
        "\"My morning started at 4:00 am with an email from my big bank stating their CEO decided not to support businesses of our size. My day ended at 1:30 am the following day with an email from WaFd Bank's CEO, Brent Beardall, confirming receipt of our preliminary loan documents.",
        "WaFd Bank's entire team worked around the clock to support community businesses during their greatest time of need. Not only did they work to support our business; they worked to provide regular communication throughout the process.",
        'WaFd got it done. I am incredibly grateful for their hard work. They have a leader that leads from the front, and I look forward to working with them for many years to come"'
      ],
      reviewer: "Jeff Widmyer",
      reviewerInfo: ["CEO, Ro Health, Inc.", "Seattle, WA"]
    }
  ];

  const videoReviewThirdSection = {
    review:
      '"WaFd Bank has been great at giving us access to lines of credit to help us grow. Having a bank you can be very upfront and honest with has been fantastic. They\'ve been on board with us 100-percent."',
    reviewer: "Chad Moffat",
    reviewerTitle: "President, Boise Mobile Equipment",
    reviewerLocation: "Boise, ID",
    videoData: {
      title: "WaFd Bank Commercial Partner - Boise Mobile Equipment",
      vimeoId: "353632824",
      minHeight: "360px",
      class: "m-auto iframe w-100 border-radius-12"
    }
  };

  const reviewsThirdSection = [
    {
      title: '"Will save us several hundred dollars a year..."',
      review: [
        '"They\'ve been to our office several times to help us set up a credit card processor that will save us several hundred dollars a year and sign paperwork for a new line of credit"'
      ],
      reviewer: "Mike Hastings",
      reviewerInfo: ["Owner, Cardinal Heating", "Kirkland, WA"]
    },
    {
      title: '"You just saved us!!"',
      review: [
        '"THANK YOU so very much for locating that check for us. You just saved us!! [...] You sure are on the ball! Your time and effort in helping us solve this problem are greatly appreciated"'
      ],
      reviewer: "Donna S"
    },
    {
      title: '"Amazing customer service!"',
      review: [
        '"Amazing customer service!! Every time I go into this bank everybody is extremely helpful and friendly! Waaaaaay better than other bigger named banks! Gilbert helped me and my father last time we were in and this is the only bank that you can actually speak to a human being!! Love this bank and location!!!"'
      ],
      reviewer: "Tiffany R.",
      reviewerInfo: ["Phoenix, AZ"]
    }
  ];

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>WaFd Bank Reviews and Testimonials</h1>
        <h3 className="text-green-60">
          We want to hear from you! Please leave a review for your{" "}
          <Link id="link-locations" to="/locations">
            local branch
          </Link>
          .
        </h3>
      </section>
      <VideoReviewSection {...videoReviewFirstSection} />
      <section className="bg-light">
        <div className="container">
          <div className="row">
            {reviewsFirstSection.map((review, index) => (
              <ReviewCard key={index} {...review} />
            ))}
          </div>
        </div>
      </section>
      <VideoReviewSection {...videoReviewSecondSection} />
      <section className="bg-light">
        <div className="container">
          <div className="row">
            {reviewsSecondSection.map((review, index) => (
              <ReviewCard key={index} {...review} />
            ))}
          </div>
        </div>
      </section>
      <VideoReviewSection {...videoReviewThirdSection} />
      <section className="bg-light">
        <div className="container">
          <div className="row">
            {reviewsThirdSection.map((review, index) => (
              <ReviewCard key={index} {...review} />
            ))}
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default Reviews;
